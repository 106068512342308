<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Award List</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-5>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="70%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: poppinsmedium"
                  outlined
                  block
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add New Award
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-card-title>
                    <span class="headline">Add Job Title</span>
                  </v-card-title>
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex xs12 align-self-center text-left pt-5>
                        <v-text-field
                          v-model="name"
                          label="Title"
                          :rules="[rules.required]"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-flex>
                     
                      <v-flex xs12 align-self-center text-left pt-5>
                        <span>Content</span>
                        <br />
                        <v-textarea
          outlined
          name="input-7-4"
          label="Notes"
          v-model="content"
          ></v-textarea>
                      </v-flex>
                    
                    </v-layout>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="categoryadd()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in category"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile flat outlined>
              <v-layout wrap justify-center py-4>
                <v-flex xs12 align-self-start text-left pa-3>
                  <span style="font-family: poppinsmedium">{{
                    item.name
                  }}</span>
                </v-flex>
                <v-flex xs12 align-self-start text-left pa-3>
                  <span style="font-family: poppinsmedium">{{
                    item.notes
                  }}</span>
                </v-flex>
                <v-flex xs12 sm6 lg6 pa-2>
                  <v-btn
                    color="success"
                    style="font-family: poppinsmedium"
                    small
                    block
                    outlined
                    @click="editcat(item)"
                    >Edit</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm6 lg6 pa-2>
                  <v-dialog persistent v-model="deleteDialog1" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Award?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteDialog1 = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs12 sm6 lg6 pa-2>
                    <v-btn
                    color="blue"
                    style="font-family: poppinsmedium"
                    small
                    block
                    outlined @click="(viewData(item._id)),(awardwiseDialog=true),(curitem2=item)"
                    
                    >View</v-btn
                  >
                   
                </v-flex>
                <!-- <v-flex xs12  pa-2>
                  <v-btn
                    color="blue"
                    style="font-family: poppinsmedium"
                    small
                    block
                    outlined
                    :to="
                      'ProductSubType?sub=' + item._id + '&name=' + item.name
                    "
                    >subTypes</v-btn
                  >
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="70%">
            <v-card tile>
              <v-card-title>
                <span class="headline">Edit Category</span>
              </v-card-title>
              <v-card-text>
                <v-layout wrap>
                  <v-flex xs12 pt-5 text-left>
                    <v-text-field
                      v-model="editingitem.name"
                      label="Award Name"
                      :rules="[rules.required]"
                      outlined
                      dense
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  
                      <v-flex xs12 align-self-center text-left pt-5>
                        <span>Content</span>
                        <br />
                         <v-text-field
                          v-model="editingitem.notes"
                          label="Title"
                          :rules="[rules.required]"
                          required
                          outlined
                          dense
                        ></v-text-field>
                      </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="getData">
                  Close
                </v-btn>
                <v-btn
                  v-if="!g"
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
                      :retain-focus="false"
                      v-model="awardwiseDialog"
                      persistent
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      scrollable
                      max-width="40%"
                      :key="awardwiseDialog"
                    >
                      <!-- <template v-slot:activator="{ on, attrs }"> -->
                       
                       
                      <!-- </template> -->
                      <v-card>
                        <v-layout wrap>
                          <v-flex
                            xs12
                            pa-4
                            text-left
                            style="background: #005f32 !important"
                          >
                            <span
                              class="kumbhBold"
                              style="color: #ffffff; font-size: 20px"
                              >{{curitem2.name}} Award History</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap fill-height>
                          <v-flex xs12 pa-2 v-if="AwardwiseList">
                            <v-card v-if="AwardwiseList.length > 0">
                              <v-simple-table>
                                <template v-slot:default>
                                  <thead>
                                    <tr>
                                      <th class="text-left">No.</th>
                                      <th class="text-left">Financial Year</th>
                                      <th class="text-left">Name</th>
                                      <th class="text-left">Edit</th>
                                      <!-- <th class="text-left">View</th> -->
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, i) in AwardwiseList" :key="i">
                                      <td class="text-left">
                                        {{ i + 1 }}
                                        <!-- <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span> -->
                                      </td>
                                      <td class="text-left">
                                        {{ item.year }}
                                      </td>
                                      <td class="text-left">
                                        {{ item.employeeId.name }}
                                      </td>
                                      <td class="text-left">
                                        <v-icon
                                          small
                                          @click="
                                            (editAwardwiseDialog = true),
                                              (curitem = item)
                                          "
                                          >mdi-pencil</v-icon
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-simple-table>
                            </v-card>
                          </v-flex>
                  </v-layout>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="error"
                            tile
                            outlined
                            @click="awardwiseDialog=false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                        <v-dialog v-model="editAwardwiseDialog" max-width="500px">
          <v-card>
            <v-card-title class="pa-0">
              <v-layout wrap style="background: #005f32 !important">
                <v-flex text-left xs12 pa-4>
                  <span style="color: white !important">Edit Award</span>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text>
              <v-form v-model="editaward" ref="editaward">
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-left pa-2 px-5 pt-8>
                    <v-select
                      v-model="curitem"
                      :items="yearList"
                      item-text="year"
                      item-value="_id"
                      outlined
                      dense
                      :rules="[rules.required]"
                      required
                      hide-details
                      label="Choose Year"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left px-5 pa-2 v-if="curitem.employeeId">
                    <v-text-field
                      v-model="curitem.employeeId.name"
                      label="Name"
                      hide-details
                      :rules="[rules.required]"
                      required
                      outlined
                      type="text"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="error"
                tile
                outlined
                @click="editAwardwiseDialog = false"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                color="#005f32"
                dark
                tile
                :disabled="!editaward"
                @click="editGrade()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      awardwiseDialog:false,
      editdialog: false,
      editingitem: [],
      gradeList:[],
      AwardwiseList:[],
      editAwardwiseDialog:false,
      curitem2:[],
      curitem:[],
      editaward:false,  
      name: "",
      content: "",
      location: "",
      jobCategory: null,
      deleteDialog1:false,
      yearList: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
    this.getyear();
  },
 

 
  methods: {

    getData() {
      this.appLoading = true;
      axios({
        url: "/award/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     viewData(item) {
      this.appLoading = true;
      axios({
        url: "/awardwise/employee/getallist",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data:{
          achievementId:item
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.AwardwiseList = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     getyear() {
      axios({
        method: "get",
        url: "/financialyear/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.yearList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      data["notes"] = this.content;
      axios({
        url: "/award/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.name = null;
            this.content=null
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["notes"] = this.editingitem.notes;
      axios({
        url: "/award/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getgrade() {
      this.appLoading = true;
      axios({
        url: "/grade/getlist",
        method: "GET",
        params: {
          employeeId: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.gradeList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      deleteItem(item) {
      var data = {};
      data["id"] = item._id;
      axios({
        url: "/award/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.deleteDialog1=false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
